@import './app/variables';
@import './app/mixins';
@import './app/typography';
@import './app/buttons';
@import './app/geometry';

@import './app/forms';
@import './app/bootstrap';
@import './app/header';
@import './app/scrollbar';
@import './app/material';

body {
  height: 100%;

  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    outline: none;
  }

  .mat-dialog-container {
    padding: 0 !important;
  }

  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}
.header {
  .mobileMenu {
    @include mq('tablet-wide', min) {
      // display: none;
    }

    .mat-icon-button {
      span {
        width: 100%;
        display: flex;
        height: 100%;
      }
    }
  }
}

// FDD
.fddForm {
  .mat-form-field-infix {
    height: 1.5rem;
    .mat-select {
      transform: translateY(-10px);
    }
  }
}

// HEADER STYLES
header.subHeader {
  .contentWrapper {
    //desctop
    .main_content {
      .mat-form-field-wrapper {
        display: flex;
        width: 100%;
        .mat-form-field-infix {
          display: flex;
          width: 100%;
          padding: 0;
          border-top: none;
          .mat-form-field-label {
            padding-top: 7px;
            padding-left: 10px;
          }
        }
      }
      .closest {
        .mat-form-field-flex {
          .mat-form-field-infix {
            // border-top: 0.84375em solid transparent;
            background: $white;
            border: 1px solid $typo-secondary;
            // padding: .5rem;
            // .mat-select-placeholder{
            //   // height: 28px;
            //   // padding-left: 1rem;
            //   // padding-top: 0;
            // }
            .mat-form-field-label {
              padding-top: 5px;
            }
          }
        }
      }
    }
    //mobile
    .tabs_content {
      min-width: 90%;
      // margin-bottom: 1rem;
      .mat-tab-group {
        width: 100%;
        //tabs
        .mat-tab-header {
          border-bottom: none;
          .mat-ripple {
            opacity: 1;
          }
          .mat-tab-label-container {
            .mat-tab-list {
              .mat-tab-labels {
                background: $white;

                outline-color: transparent;
                width: calc(100% - 1rem);
                margin: 0.5rem auto;
                border-radius: 7px;
                overflow: hidden;
                border: 1px solid $typo-secondary;
                .mat-tab-label-content {
                  @include mq('phone-wide', max) {
                    font-size: 1.25rem;
                  }
                  font-size: 1.5rem;
                }
                .mat-tab-label {
                  padding: 0 1rem;
                  width: 50%;
                  height: 56px;
                  display: flex;
                  color: $typo-secondary;
                  justify-content: center;
                  .icon_active {
                    display: none;
                  }
                  .icon_noactive {
                    display: flex;
                  }
                }
                .mat-tab-label-active {
                  background: $typo-secondary;
                  color: $white;
                  .icon_active {
                    display: flex;
                  }
                  .icon_noactive {
                    display: none;
                  }
                }
              }
              .mat-ink-bar {
                background: transparent;
              }
            }
          }
        }

        //tabs content
        .mat-tab-body-wrapper {
          .mat-form-field {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            margin-left: 0 !important;
            // padding-top: 0 !important;
            padding: 1rem 1rem;
            // margin-top: 1.5rem;

            .mat-form-field-wrapper {
              display: flex;
              width: 100%;
              top: 0;
              padding-top: 0;
              padding-bottom: 0;
              .mat-form-field-infix {
                display: flex;
                padding: 0;
                // padding-top: 1.5rem;
                border-top: none;
                span {
                  label {
                    padding-top: 7px;
                    padding-left: 10px;
                  }
                }
                button {
                  background: $typo-secondary;
                  border-radius: 5px;
                  color: $white;
                  height: 46px;
                  width: 46px;
                }
                // .mat-form-field-label{

                // }
              }
            }
            &.select {
              .mat-form-field-wrapper {
                // padding-top: 1.5rem;
                .mat-form-field-flex {
                  // height: 30px;
                  .mat-form-field-infix {
                    display: flex;
                    box-sizing: border-box;
                    // height: 100%;
                    background: $white;
                    border: 1px solid $typo-secondary;
                    padding: 0;
                    // padding: 1rem 0;
                  }
                }
                .mat-form-field-label {
                  padding-top: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .mat-form-field-underline {
    display: none;
  }
}

mat-select {
  .mat-select-trigger {
    height: 40px;
  }
  .mat-select-value-text {
    height: 40px;
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 8px;
  }
}

.mobileStateList {
  label {
    padding-top: 5px;
    padding-left: 4px;
  }
}
.storeInfo {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
.descript {
  .mat-list-item-content {
    padding: 1rem;
  }
}
.services {
  .mat-list-item-content {
    align-items: flex-start !important;
    padding: 0 !important;
  }

  &.mat-list-base .mat-list-item .mat-line {
    white-space: unset;
    overflow: unset;
  }

  .mat-list-text {
    align-items: center;
  }

  .mat-line {
    max-width: 90px;

    &:first-child {
      width: 64px;
      height: 64px;
      max-width: 64px;
      background-color: #fff;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-bottom: 3px !important;
    }
  }
}
.store {
  .mat-expansion-panel-content {
    background-color: #f5f5f5;
  }
}

// burger menu styles
.cdk-overlay-container {
  .cdk-overlay-backdrop {
    background: #000;
    opacity: 0.5;
  }
  .cdk-overlay-connected-position-bounding-box {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    .cdk-overlay-pane {
      // top: 0 !important;
      // right: 0 !important;
      // width: 50%;
      // height: 100vh;
      .mat-menu-panel {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        border-radius: 0;
        overflow: hidden;
        .mat-menu-content {
          display: flex;
          flex-direction: column;
          padding: 1rem;
          height: 100vh;
          .sidenav_logo {
            display: flex;
            width: 100%;
            padding: 1.5rem;
            box-sizing: border-box;
            img {
              width: 100%;
              max-width: 220px;
            }
          }
          button,
          a {
            padding: 0;
            margin-bottom: 1rem;
            margin-left: 1.5rem;
            .nav_item {
              display: flex;
              align-items: center;

              .icon {
                @include mq('phablet', max) {
                  width: 15%;
                }
                display: flex;
                width: 45px;
                // height: 3rem;
                margin-right: 1rem;
                img {
                  width: 100%;
                }
              }
              span {
                width: 85%;
                color: $typo-secondary;
                text-transform: uppercase;
                font-size: 1.7rem;
                font-weight: 100;
              }
            }
          }
        }
      }
    }
  }
}
// END HEADER STYLES

// LOCATION PAGE STYLES

.mobileStateList {
  .mat-form-field.select {
    background: $white;
    border: 1px solid $typo-secondary;
    height: 40px;
    padding-left: 8px;
    .mat-form-field-infix {
      border-top: none;
      padding: 0;
    }
  }
  .mat-form-field-underline {
    display: none;
  }
}

.storeInfo {
  .contentwrapper {
    .stores {
      .mat-card.store {
        .title {
          background: $white;
        }
      }
    }
  }
}

body:not(.support) #beacon-container {
  display: none;
}

.StatusBlock .infoPackage a.delivery {
  display: inline-block;
  font-size: 1.25rem;
  color: blue;
  line-height: 1.75rem;
  font-weight: 100;
}

.StatusBlock .infoPackage a.delivery:hover {
  text-decoration: underline;
}

.blue-link {
  display: inline;
  color: #2a2770;
  font-weight: 400;
  font-size: 1.25rem;
}

@include mq('phone-wide', max) {
  .mat-expansion-panel-header[aria-expanded='false'] {
    padding: 8px 24px;
  }

  .mat-expansion-panel-header-description {
    flex-grow: 5;
  }

  div.mat-select-panel {
    max-height: 50vh;
    .mat-option {
      height: 5em;
      .mat-option-text {
        overflow: unset;
        font-size: 2rem;
      }
    }
  }
}

.w100p {
  width: 100%;
}
