@import 'variables.scss';

html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;

  @include mq('desktop', max) {
    font-size: 16px;
  }

  @include mq('tablet-small', max) {
    font-size: 14px;
  }

  @include mq('phone-wide', max) {
    font-size: 12px;
  }
}

body {
  font-family: $primary-font;
  color: $typo-primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.pageTitle {
    font-weight: 200;
    color: rgba(42, 39, 112, 255);
    background-color: #f4f4f8;
    text-transform: uppercase;
    // padding: 0;
    padding: 0.5rem 0;
    width: 100%;
  }
  color: black;
  margin: 0;
  line-height: 1.15;
  margin: 1rem 0;
}

p {
  &.blue {
    color: $primary-blue;
  }
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

label {
  display: inline-block;
  margin: 0;
}

h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  font-size: inherit;
  font-weight: 600;
}

ul li {
  &.white {
    color: $white;
  }

  margin: 1rem 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 100;
  color: black;
  text-align: justify;
}

span {
  &.white {
    color: white;
  }
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
}

p,
address {
  &.white {
    color: $white;
  }

  margin: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 100;
  color: black;
  text-align: justify;
}

a {
  &.white {
    color: white;
  }
  &.gray {
    color: #bfbfbf;
  }

  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  line-height: 1.25rem;
  text-decoration: none;
  font-weight: 200;
  transition: color 0.3s linear;

  .mat-icon {
    margin-right: 0.25rem;
  }

  &:hover {
    color: #337ab7;
  }
}
