/* Grayscale */
$black: #000000;
$white: #ffffff;

// Colors

$primary-orange: #f7ab00;
$secondary-orange: #ffc647;

$primary-blue: #016fd0;

$primary-green: #77ac44;

$primary-red: #f25f3e;
$secondary-red: #ec5d3d;

$primary-grey: #e7eef3;

$typo-primary: #3b4f5b;
$typo-secondary: #2a2770;
$typo-default: rgba(0, 0, 0, 0.87);

// Fonts
$primary-font: 'Roboto';
$secondary-font: 'Roboto';

//media query sizes
$breakpoints: (
  'phone': 400px,
  'phone-wide': 500px,
  'phablet': 560px,
  'tablet-small': 640px,
  'tablet': 770px,
  'tablet-new': 970px,
  'tablet-wide': 1100px,
  'desktop': 1340px,
  'desktop-wide': 1440px,
  'full-hd': 1920px,
);
