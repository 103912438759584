.group {
  label {
    color: rgba(255, 255, 255, 0.75);
    margin: 0 0 0.5rem 1.5rem;
    align-self: flex-start;
    font-weight: 200;
  }

  position: relative;
  display: flex;
  margin: 1rem 0;
  height: 5rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
  input,
  button {
    box-sizing: border-box;
    outline: none;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-family: $primary-font;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 200;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
      font-size: 1rem;
      font-weight: 200;
    }
  }

  button {
    width: 100%;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    &:disabled {
      color: rgba(255, 255, 255, 0.25);
    }
  }

  .mat-error {
    transition: all 0.3s linear;
    margin: 0.5rem 1.5rem 0 0;
    font-size: 0.75rem !important;
    font-weight: 200 !important;
    align-self: flex-end;
  }
}
