@import 'variables';

span.rectCicrle {
  display: block;
  border-radius: 50%;
  border: 1rem solid $primary-blue;
  box-sizing: border-box;
  border-radius: 90px;
  width: 6.5rem;
  height: 6.5rem;

  &.large {
    width: 12rem;
    height: 12rem;
    border-width: 1.5rem;
  }

  &.blue {
    border-color: $primary-blue;
  }

  &.orange {
    border-color: $primary-orange;
  }

  &.red {
    border-color: $primary-red;
  }

  &.green {
    border-color: $primary-green;
  }
}

span.rectRhombus {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  border: 1rem solid $primary-blue;
  box-sizing: border-box;
  border-radius: 1.25rem;
  transform: rotate(-45deg);

  &.small {
    width: 3rem;
    height: 3rem;
    border-radius: 0.75rem;
    background: $primary-blue;
  }

  &.extraSmall {
    border-radius: 0.5rem;
    width: 2rem;
    height: 2rem;
    background: $primary-blue;
  }

  &.small,
  &.extraSmall {
    &.blue {
      border-color: $primary-blue;
      background: $primary-blue;
    }

    &.orange {
      border-color: $primary-orange;
      background: $primary-orange;
    }

    &.red {
      border-color: $primary-red;
      background: $primary-red;
    }

    &.green {
      border-color: $primary-green;
      background: $primary-green;
    }
  }

  &.blue {
    border-color: $primary-blue;
  }

  &.orange {
    border-color: $primary-orange;
  }

  &.red {
    border-color: $primary-red;
  }

  &.green {
    border-color: $primary-green;
  }
}

div.languageCircle {
  @include flex-vertical {
    align-items: center;
  }

  cursor: pointer;
  margin: 0.75rem;

  .circle {
    @include flex-vertical {
      align-items: center;
      justify-content: center;
    }
    img {
      transform: scale(1.25);
    }
    overflow: hidden;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0px 0.5rem 1rem rgba(66, 98, 119, 0.2);
  }

  span {
    margin-top: 1rem;
  }
}
